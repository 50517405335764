<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Shipping Methods List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['shipping_methods/create'])"
                       :to="'/shipping_methods/create'"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'shipping_methods/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            
            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['shipping_methods/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['shipping_methods/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/shipping_methods/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i>
                </router-link>

               <a v-if="$store.getters['auth/checkAccess']('shipping_methods/delete')" v-tooltip.top-center="'Delete'"
                  class="btn-danger btn-simple btn-link"
                  @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i>
              </a>

              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this shipping_methods item?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";


export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'code', value: 'code', minWidth: '200', align: 'center'},
        {label: 'Fee', value: 'fee', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,
      componentKey: 0,
    }

  },
  created(){
    // this.responsiveViewPort();
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("shipping_methods/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Beverage updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("shipping_methods/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Shipping Methods Item deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    // responsiveViewPort(){
    //     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //         this.fixedColumn = null;
    //         this.tableColumns = [
    //             {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
    //             {label: 'Ident', value: 'ident', minWidth: '200', align: 'center'},
    //             {label: 'Value', value: 'value', minWidth: '200', align: 'center'},
    //         ];
    //     }else{
    //         this.fixedColumn = 'right';
    //         this.tableColumns = [
    //             {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
    //             {label: 'Description', value: 'description', minWidth: '200', align: 'center'},
    //             {label: 'Ident', value: 'ident', minWidth: '200', align: 'center'},
    //             {label: 'Value', value: 'value', minWidth: '200', align: 'center'},
    //         ];
    //     }
    // }
  },
}
</script>
<style>
</style>
