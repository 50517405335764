<template>

    <div class="row" ref="itemView">
  
      <div class="col-12">
  
        <card>
          <div slot="header">
            <div class="header-content">
              <div class="form-title">
                <h4 class="card-title">
                  {{ formTitle }}
                </h4>
              </div>
            </div>
          </div>
  
          <div id="contactus-message" class="card-body">
            <div class="container">
              <div class="row contact-info">
                <div class="col-12 col-md-12 col-lg-12">
                 <div class="member-box">
                   <span class="title-label">Custom Order Information</span>
                   <div class="row">
                     <div class="col-md-9">
                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Full Name</span>
                             <span class="category-value">  {{ itemDetails.full_name }} </span>
                           </p>
                         </div>

                       </div>

                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Email</span>
                             <span  class="category-value"> {{ itemDetails.email }}</span>
                           </p>
                         </div>
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Phone</span>
                             <span class="category-value">{{ itemDetails.phone_number }}</span>
                           </p>
                         </div>
                       </div>

                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Ordered Service</span>
                             <span class="category-value">  {{ itemDetails.service.name }} </span>
                           </p>
                         </div>

                       </div>



                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">How to recieve it: </span>
                             <span class="category-value">  {{ itemDetails.method }} </span>
                           </p>
                         </div>

                       </div>

                       <div class="row" v-if="itemDetails.method == 'delivery'">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Address</span>
                             <span  class="category-value"> {{ itemDetails.address }}</span>
                           </p>
                         </div>
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">State</span>
                             <span class="category-value">{{ itemDetails.state }}</span>
                           </p>
                         </div>
                       </div>

                       <div class="row" v-if="itemDetails.method == 'pickup'">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Pickp Date</span>
                             <span class="category-value">  {{ itemDetails.pickup_date }} </span>
                           </p>
                         </div>

                       </div>


                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Number of people</span>
                             <span class="category-value">  {{ itemDetails.number_people }} </span>
                           </p>
                         </div>

                       </div>

                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Budget</span>
                             <span class="category-value">  {{ itemDetails.budget }}$ </span>
                           </p>
                         </div>

                       </div>


                      <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Event date and time   </span>   
                             <span class="category-value">  {{ itemDetails.date }} {{ itemDetails.time }} </span>
                           </p>
                         </div>

                       </div>

                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Description</span>
                             <span class="category-value">  {{ itemDetails.description }} </span>
                           </p>
                         </div>

                       </div>

                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Refernce link</span>
                             <span class="category-value">  {{ itemDetails.reference_link }} </span>
                           </p>
                         </div>

                       </div>


                     </div>
                   </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="card-footer text-right">
  
          </div>
        </card>
  
  
      </div>
      
    </div>
  </template>
  <script>
  // import { jsPDF } from "jspdf";
  
  export default {
  
    components: {},
  
    data() {
      return {
        id: undefined,
        formTitle: "",
        submitting: false,
        itemDetails: null,
        imagePath: process.env.VUE_APP_SERVER_IMAGE_URL,
        showMenuModalVisibility: false,
        currentMenu: [],
  
      };
    },
  
    mounted() {
  
      this.loader = this.$loading.show({
        container: this.$refs.itemView
      });
  
      this.id = this.$route.params['id'];
      this.formTitle = "Custom Order Details";
      if (this.id !== undefined) {
        this.getItem();
      } else {
        this.$notify({
          message: "Custom Order Not Found",
          timeout: 2000,
          type: 'danger'
        });
        this.loader.hide();
      }
    },
  
  
    methods: {
  
      getItem() {
        this.axios.post("custom_orders/get", {id: +this.id}).then((response) => {
          this.itemDetails = response.data;
          this.loader.hide();
        }).catch((error) => {
          if (error.response.status === 404) {
            this.$notify({
              message: "Custom Order Not Found",
              timeout: 2000,
              type: 'danger'
            });
            this.loader.hide();
  
          } else {
            console.error(error);
          }
        })
      },
      getValueOrDash(obj, key) {
        if (obj && obj[key]) {
          return obj[key];
        }
        return "-";
      },
    }
  }
  </script>
  
  <style>
  .header-content{
    display: flex;
    justify-content: space-between;
  }
  .typo-line{
    padding-left: 65px;
    margin-bottom: 20px;
  }
  .contact-info{
    margin-bottom: 20px;
  }
  .category{
    color: #888888;
  }
  .category-2{
    top: 11px !important;
  }
  .category-value{
    margin-left: 66px;
  }
  /*.message-content{*/
  /*  font-size: 16px !important*/
  /*}*/
  .member-box {
    position: relative;
    border: 1px solid black;
    padding: 33px;
    padding-left: 30px;
    margin-bottom: 30px;
  }
  .title-label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
  }
  .member-profile-image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .address-box {
    width: 50%;
   }
   .member-box.address {
    display: flex;
    justify-content: space-between;
    }
  </style>