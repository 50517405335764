<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Custom Orders List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['custom_orders/create'])"
                       :to="'/custom_orders/create/'"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>
        <div>
          <general-data-table
            ref="table"
            :sortable="true"
            :row-key="'id'"
            :key="componentKey"
            :api-url="'custom_orders/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['custom_orders/view']) ||
                                     $store.getters['auth/checkAccessOwner']( scope.row.creator_id)"
                               v-tooltip.top-center="'View'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/custom_orders/view/'+scope.row.id">
                    <i class="fa fa-eye"></i>
                  </router-link>

                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import LSwitch from "../../components/Switch";

export default {
  components: {
    LSwitch,
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Full Name', value: 'full_name', minWidth: '200', align: 'center'},
        {label: 'Service', value: 'service.name', minWidth: '200', align: 'center'},
        {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'Phone Number', value: 'phone_number', minWidth: '200', align: 'center'},
        {label: 'created At', value: 'created_at', minWidth: '200', align: 'center'}
      ],
      qrCodeModalVisibility: false,
      deleteModalVisibility: false,
      currentObject: {},
      toDeleteId: undefined,
      componentKey: 0,
    }
  },
  methods: {

  },
}
</script>

